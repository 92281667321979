/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import Helmet from 'react-helmet';

const SEO = () => (
    <Helmet>
        <link rel="canonical" href={`https://${process.env.GATSBY_DOMAIN_NAME}`} />
        <meta property="og:url" content={`https://${process.env.GATSBY_DOMAIN_NAME}`} />
        <meta name="twitter:url" content={`https://${process.env.GATSBY_DOMAIN_NAME}`} />

        <title>{process.env.GATSBY_BLOG_TITLE}</title>
        <meta property="og:title" content={process.env.GATSBY_BLOG_TITLE} />
        <meta name="twitter:title" content={process.env.GATSBY_BLOG_TITLE} />

        <meta name="description" content={process.env.GATSBY_BLOG_DESCRIPTION} />
        <meta property="og:description" content={process.env.GATSBY_BLOG_DESCRIPTION} />
        <meta name="twitter:description" content={process.env.GATSBY_BLOG_DESCRIPTION} />

        <meta property="og:locale" content={process.env.GATSBY_LOCALE_LANGUAGE} />
        <meta name="author" content={process.env.GATSBY_COMPANY_NAME} />

        <link
            rel="icon"
            href={`https://${process.env.GATSBY_DOMAIN_NAME}/icons/logo-192x192.png`}
            sizes="192x192"
            type="image/png"
        />
        <link
            rel="shortcut icon"
            href={`https://${process.env.GATSBY_DOMAIN_NAME}/icons/logo-48x48.png`}
            sizes="48x48"
            type="image/png"
        />

        <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
);

export default SEO;
