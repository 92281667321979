/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Header from './header';
import Footer from './Footer';

import './layout.css';

const Layout = ({ children }) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    link1Text
                    link1Url
                    link2Text
                    link2Url
                }
            }
        }
    `);

    const siteMetadata = data && data.site && data.site.siteMetadata;

    // need to precise the path in the environment because of .jpg vs .png
    const backgroundImage = process.env.GATSBY_BACKGROUND_IMAGE
        ? {
              backgroundImage: `url(${process.env.GATSBY_BACKGROUND_IMAGE})`,
              backgroundPosition: 'center top',
              backgroundRepeat: 'no-repeat',
          }
        : {};

    return (
        <div
            style={{
                display: 'flex',
                minHeight: '100vh',
                flexDirection: 'column',
                backgroundColor: process.env.GATSBY_BACKGROUND_COLOR,
                ...backgroundImage,
            }}
        >
            {siteMetadata ? (
                <Header
                    link1Text={siteMetadata.link1Text}
                    link1Url={siteMetadata.link1Url}
                    link2Text={siteMetadata.link2Text}
                    link2Url={siteMetadata.link2Url}
                />
            ) : null}

            <div
                style={{
                    margin: `${process.env.GATSBY_STARTING_HEIGHT}px auto -50px auto`,
                    maxWidth: 960,
                    paddingTop: 0,
                    flexGrow: 1,
                }}
            >
                <main>{children}</main>
            </div>
            <Footer />
        </div>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
