import React from 'react';
import styled from 'styled-components';

export const LeftLogoHeader = ({ link1Url }) => (
    <HeaderComponent>
        <HeaderWrapperDiv>
            <LogoLink href={link1Url}>
                <LogoDiv />
            </LogoLink>
        </HeaderWrapperDiv>
    </HeaderComponent>
);

const HeaderComponent = styled.header`
    background: ${process.env.GATSBY_HEADER_BG_COLOR};
    height: 85px;
`;

const HeaderWrapperDiv = styled.div`
    width: 100%;
    height: 100%;
    flex-direction: row;
    align-items: center;
    position: relative;
    display: flex;
    margin: 0 auto;
    margin-left: 8%;
`;

const LogoDiv = styled.div`
    width: 200px;
    height: 75%;
    object-fit: contain;
    background-size: contain;
    background-repeat: no-repeat;
    object-position: left;
    display: flex;
    position: relative;
    background-image: url('/icons/left-header-logo.png');
`;

const LogoLink = styled.a`
    cursor: pointer;
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
`;
