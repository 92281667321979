import React from 'react';
import styled from 'styled-components';
import { device } from './HomeCardTable';

const Footer = () => (
    <footer
        style={{
            background: process.env.GATSBY_HEADER_BG_COLOR,
            height: '50px',
        }}
    >
        <HeaderWrapperDiv>
            <TextBrandJoinDiv>
                © {new Date().getFullYear()}, Made with
                <LinkBrandJoin href="https://www.join-stories.com">Join Stories</LinkBrandJoin>
            </TextBrandJoinDiv>
        </HeaderWrapperDiv>
    </footer>
);

export default Footer;

const HeaderWrapperDiv = styled.div`
    width: 100%;
    height: 100%;
    max-width: 850px;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    display: flex;
    margin: 0 auto;
`;

const TextBrandJoinDiv = styled.div`
    color: rgb(256, 256, 256, 0.7);
    text-decoration: none;
    decoration: none;
    font-size: 1.5rem;
`;

const LinkBrandJoin = styled.a`
    padding: 0.6rem;
    color: rgb(256, 256, 256, 0.8);
    text-decoration: none;
    decoration: none;
    font-weight: bold;
    font-size: 1.5rem;
    border-radius: 1rem;
    border: 1px solid rgb(256, 256, 256, 0.4);
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
    margin: 0 0 0 0.7rem;

    @media ${device.tablet} {
        margin: 0 2rem 0 0.7rem;
    }
    @media ${device.small} {
        margin: 0 2rem 0 0.7rem;
    }
`;
