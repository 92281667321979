import PropTypes from 'prop-types';
import React from 'react';
import { MiddleLogoHeader } from './headers/MiddleLogoHeader';
import { LeftLogoHeader } from './headers/LeftLogoHeader';

const HeaderType = {
    leftLogo: 'leftLogo',
    middleLogo: 'middleLogo',
};

const renderHeader = (type) => {
    switch (type) {
        case HeaderType.leftLogo:
            return LeftLogoHeader;
        case HeaderType.middleLogo:
            return MiddleLogoHeader;
        default:
            return MiddleLogoHeader;
    }
};

const Header = (props) => {
    const ChosenHeader = renderHeader(process.env.GATSBY_HEADER_TYPE);
    return <ChosenHeader {...props} />;
};

Header.propTypes = {
    link1Text: PropTypes.string,
    link1Url: PropTypes.string,
    link2Text: PropTypes.string,
    link2Url: PropTypes.string,
};

Header.defaultProps = {
    link1Text: '',
    link1Url: '',
    link2Text: '',
    link2Url: '',
};

export default Header;
